import React, {ReactNode} from 'react';
import {Link} from 'gatsby';
import GatsbyImage from '../../components/gatsby-image';
import SVGImage from "../svg-image/svg-image";
import {
    PostCard,
    PostCardFront,
    PostCardBack,
    PostCardDescription,
    PostCardTags,
    PostCardTitle,
} from './post-card-minimal.style';

import Tag from "../tag/tag";

interface PostCardMinimalProps {
    image: any | null;
    color?: string;
    title?: string;
    description?: string;
    url?: string;
    // date?: string;
    tags?: [];
    className?: string;
    fadeIn?: boolean;
    doHover?: boolean;
    doDarken?: boolean;
    doLink?: boolean;
    iconSize?: string;
}

const PostCardMinimal: React.FunctionComponent<PostCardMinimalProps> = ({
                                                                            image = '',
                                                                            color = '#f00',
                                                                            title = '',
                                                                            description,
                                                                            url = '/',
                                                                            tags = [],
                                                                            className = '',
                                                                            // fadeIn = false,
                                                                            doHover = true,
                                                                            doDarken= false,
                                                                            doLink = true,
                                                                            iconSize = '50%'
                                                                        }) => {
    if (image === null) {
        image = ''
    }
    if (image && image.publicURL && image.publicURL.endsWith('.svg')) {
        image = image.publicURL;
    } else if (image && image.childImageSharp) {
        image = image.childImageSharp.gatsbyImageData;
    }

    // const [isLoaded, setIsLoaded] = useState(false);

    // Add all classes to an array
    const addAllClasses = ['post-card'];

    // className prop checking
    if (className) {
        addAllClasses.push(className);
    }

    if (doHover) {
        addAllClasses.push('do-hover')
    }

    // const handleImageLoad = () => {
    //     setIsLoaded(true);
    // };

    const getImageComponent = () => {
        if (typeof image === 'string') {
            return <SVGImage
                src={image}
                color={color}
                doDarken={doDarken}
                alt={title}
                iconSize={iconSize}
            />
        } else {
            return (
                <GatsbyImage
                    src={image}
                    alt={title}
                    // onLoad={() => handleImageLoad()}
                />
            );
        }
    };

    interface LinkWrapperProps {
        doLink?: boolean;
        children: ReactNode;
        url: string;
    }

    const LinkWrapper = ({doLink = true, children, url}: LinkWrapperProps) => {
        // Since it is bad practice to nest links, this enables a toggle to turn linking off
        return doLink ? <Link to={url}>{children}</Link> : <>{children}</>;
    };


    return (
        <LinkWrapper doLink={doLink} url={url}>
            <PostCard
                className={addAllClasses.join(' ')}
                // onLoad={handleImageLoad}
                style={{
                    // opacity: fadeIn && !isLoaded ? 0 : 1,
                    transition: 'opacity .5s ease-in'
                }}>
                <PostCardFront className={'post-preview'}>
                    {getImageComponent()}
                    {title && (
                        <PostCardTitle className={(typeof image === 'string' ? 'svg-img' : 'pix-img') + ' post-title'}>
                            {title}
                        </PostCardTitle>
                    )}
                </PostCardFront>
                {doHover && <PostCardBack className="post-description">
                    {description == null ? null : (
                        <PostCardDescription className="post-text">
                            {description}
                        </PostCardDescription>
                    )}
                    <h4 className="read-more">Read more</h4>
                    <PostCardTags className="post-tags">
                        {tags == null ? null : (
                            tags.map((tag: string, index: number) => (
                                <Tag
                                    key={index}
                                    text={tag}
                                    isLink={false}
                                />
                            ))
                        )}
                    </PostCardTags>
                </PostCardBack>}
            </PostCard>
        </LinkWrapper>
    );
};

export default PostCardMinimal;
