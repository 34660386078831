import React from 'react';
import {graphql} from 'gatsby';
import urljoin from 'url-join';
import Layout from '../components/layout';
import SEO from '../components/seo';
import PostDetails from '../components/post-details/post-details';
import SocialBox from "../components/social-share/social-share";
import Tag from "../components/tag/tag";

import {
    BlogPostDetailsWrapper,
    RelatedPostWrapper,
    RelatedPostItems,
    RelatedPostTitle,
    RelatedPostItem,
    BlogPostFooter,
    // PostTags,
} from './templates.style';
import PostCardMinimal from "../components/post-card-minimal/post-card-minimal";
import {CounterFallbacks} from "../interact";

interface PostProps {
    data: {
        site: {
            siteMetadata: {
                siteUrl: string;
            }
        }
        markdownRemark: {
            fields: {
                slug: string;
            };
            frontmatter: {
                title: string;
                description: string;
                tags: string[];
                date: string;
                cover: {
                    publicURL: string;
                    childImageSharp: {
                        gatsbyImageData: any;
                    };
                }
                color: string;
                audio: number;
                claps?: number;
                views?: number;

            }
            html: string;
        }
        allMarkdownRemark: {
            edges: Array<{
                node: {
                    fields: {
                        slug: string;
                    }
                    frontmatter: {
                        title: string;
                        description: string;
                        tags: string[];
                        color: string;
                    }
                }
            }>
        }
        speechFile: {
            publicURL: string;
        } | null
        textFile: any | null;
        preview: {
            publicURL: string;
        }
    }
}

const BlogPostTemplate: React.FunctionComponent<PostProps> = ({data}) => {
    let post = data.markdownRemark;
    const {edges} = data.allMarkdownRemark;
    const title = post.frontmatter.title;
    const slug = post.fields.slug;
    const siteUrl = data.site.siteMetadata.siteUrl;
    const shareUrl = urljoin(siteUrl, slug);
    const audioURL = data.speechFile ? data.speechFile.publicURL : null;
    const timeModified = data.textFile.mtime;
    const claps = post.frontmatter.claps;
    const views = post.frontmatter.views;
    const n_fallbacks: CounterFallbacks = {views: views ?? 0, claps: claps ?? 0}
    // The hardcoded fallbacks are not functional yet.
    // Also, there is an error with it somehow always being {1, 1}
    // So for now, lets hardcode our issue out
    // const n_fallbacks = {views: 0, claps: 0}


    let contentUntilSeparator = "No outline was set";
    let contentAfterSeparator = post.html;

    if (post.html.includes('XXX')) {
        contentUntilSeparator = post.html.split('XXX')[0];
        contentAfterSeparator = post.html.split('XXX')[1];
    }
    const mediaUrl = urljoin(siteUrl, slug)
    return (
        <Layout do_recaptcha={true}>
            <SEO
                title={title}
                description={post.frontmatter.description}
                image={data.preview ? data.preview.publicURL : null}
                modifiedTime={timeModified}
                url={mediaUrl}
            />
            <BlogPostDetailsWrapper>
                <PostDetails
                    slug={slug}
                    title={title}
                    date={post.frontmatter.date}
                    preview={post.frontmatter.cover}
                    color={`#${post.frontmatter.color}`}
                    audio={post.frontmatter.audio}
                    description={contentAfterSeparator}
                    outline={contentUntilSeparator}
                    tags={post.frontmatter.tags}
                    audioURL={audioURL}
                    n_fallbacks={n_fallbacks}
                />
                <BlogPostFooter
                    className={post.frontmatter.cover == null ? 'center' : ''}
                >
                    {post.frontmatter.tags == null ? null : (<span>
                        {post.frontmatter.tags.map((tag: string, index: number) => (
                                <Tag key={index} text={tag}/>
                            ))}
                    </span>)}
                    <SocialBox
                        shareUrl={shareUrl}
                        description={post.frontmatter.description}
                        title={title}
                        media={mediaUrl}
                    />
                </BlogPostFooter>
            </BlogPostDetailsWrapper>

            {edges.length !== 0 && (
                <RelatedPostWrapper>
                    <RelatedPostTitle className={"transition-join"}>
                        Related Posts
                    </RelatedPostTitle>
                    <RelatedPostItems>
                        {edges.map(({node}: any) => (
                            <RelatedPostItem key={node.fields.slug}>
                                <PostCardMinimal
                                    key={node.fields.slug}
                                    title={node.frontmatter.title}
                                    image={node.frontmatter.cover}
                                    color={`#${node.frontmatter.color}`}
                                    url={node.fields.slug}
                                    description={node.frontmatter.description}
                                    tags={node.frontmatter.tags}
                                    doDarken={true}
                                />
                            </RelatedPostItem>
                        ))}
                    </RelatedPostItems>
                </RelatedPostWrapper>
            )}
        </Layout>
    );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!, $tag: [String!]) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      fields {
        slug
      }
      frontmatter {
        title
        date(formatString: "MMM 'YY")
        description
        tags
        color
        audio
        claps
        views
        cover {
          publicURL
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
    allMarkdownRemark(
      limit: 3
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: { tags: { in: $tag } }
        fields: { slug: { ne: $slug } }
      }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            description
            tags
            color
            audio
            cover {
              publicURL
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
    }
    speechFile : file(
      sourceInstanceName: { eq: "blog" }
      base: { eq: "speech.mp3" }
      relativeDirectory: { regex: $slug }
    ) {
      publicURL
    }
    textFile : file(
      sourceInstanceName: { eq: "blog" }
      base: { eq: "index.md" }
      relativeDirectory: { regex: $slug }
    ) {
      mtime
    }
  preview : file(
    sourceInstanceName: {eq: "blog"}
    name: {eq: "image"}
    extension: {eq: "png"}
    relativeDirectory: { regex: $slug }
   ) {
     publicURL
    }
  }
  
`;
