import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const PostCard = styled.div`
  border-radius: 6px;
  //width: 256px;
  //height: 256px;
  //@media (max-width: 524px) {
  //  width: 100%;
  //  height: 128px;
  //}
  position: relative;
  overflow: hidden;
  //pointer-events: auto;
 
  .post-preview, .post-description, .post-title {
    width: 100%;
    height: 100%;
    //transition: all .3s ease-in-out;
  }
  .gatsby-image-wrapper {
    height: 100%;
  }
  &.do-hover:hover {
    .post-title {
      opacity: 0;
    }
    .post-preview {
      opacity: 0.10;
    }
    .post-description {
      opacity: 1.0;
    }
  }
`;

export const PostCardBack = styled.div`
  //display: grid;
  
  padding: 15px;
  //text-align: left;
  //opacity: 0;
  //@media (min-width: 524px) {
  //  padding: 25px;
  //}
  h4 {
    color: ${({ theme }) => theme.text};
  }
`;

// export const ReadMore  = styled.h4`
 //padding-top: 4px;
 //&:hover {
 //  color: ${themeGet('colors.primary', '#2251ff')};
 //}
// `;

interface PostCardTitleProps {
  className?: string; // Define the type for imageType prop
}

export const PostCardTitle = styled.h1<PostCardTitleProps>`
  font-size: 30px;
  @media (max-width: 360px) {
    font-size: 26px;
  }
  @media (max-width: 300px) {
    font-size: 22px;
  }
  padding: 10px;
  transform: translateY(-100%);
  line-height: 1em;
  height: 100%;
  text-shadow: ${({ className, theme }) => (className && className.includes('svg-img') ? 'none' : `0 0 10px ${theme.text}`)};
  color: ${({ theme }) => theme.body};
`;

export const PostCardFront = styled.div`
  //pointer-events: none;
  position: absolute;
`;


export const PostCardDescription = styled.div`
  //align-self: flex-start;
  color: ${({ theme }) => theme.text};
  
  margin-bottom: 0.5em;
  @media (min-width: 524px) {
    margin-bottom: 1em;
  }
`;

export const PostCardTags = styled.div`
  // color: ${themeGet('colors.primary', '#2251ff')};
  align-self: flex-end;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  span {
    margin-bottom: 4px;
  }
`;