import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';


// export const BlogPostsWrapper = styled.div`
//   margin: 0 auto;
//   padding-top: 120px;
//   position: relative;
//   width: 100%;
//   @media (min-width: 990px) {
//     width: 900px;
//   }
//   @media (min-width: 1200px) {
//     width: 1050px;
//   }
//   @media (min-width: 1400px) {
//     width: 1170px;
//   }
//   @media (max-width: 990px) {
//     padding: 80px 45px 0 45px;
//   }
//   @media (max-width: 575px) {
//     padding: 60px 25px 0 25px;
//   }
//
//   .post-card {
//     margin-bottom: 90px;
//     @media (max-width: 990px) {
//       margin-bottom: 60px;
//     }
//     @media (max-width: 575px) {
//       margin-bottom: 30px;
//     }
//   }
// `;



export const RelatedPostWrapper = styled.div`
  margin: 0 auto;
  padding-left: 75px;
  padding-right: 75px;
  @media (min-width: 1550px) {
    width: 1500px;
    padding-left: 0;
    padding-right: 0;
  }
  @media (max-width: 990px) {
    padding: 0 45px 0 45px;
  }
  @media (max-width: 575px) {
    padding: 0 4px 0 4px;
  }
`;

export const RelatedPostTitle = styled.h2`
  color: ${({ theme }) => theme.semiforeground};
  font-size: 16px;
  font-weight: 500;
  font-family: ${themeGet('fontFamily.0', "'Fira Sans',sans-serif")};
  letter-spacing: 0.17em;
  position: relative;
  margin-bottom: 30px;

  &:after {
    content: '';
    width: 68px;
    height: 1px;
    background-color: ${({ theme }) => theme.semiforeground};
    //transition: background-color 0.25s ease-in;
    display: block;
    margin-top: 8px;
  }
`;
export const RelatedPostItems = styled.div`
  //max-width: 58%;
  //max-width: 100%;
  //margin: auto;
  justify-content: center;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 4px;
`;

export const RelatedPostItem = styled.div`
  width: 100%;
  max-width: 256px;
  margin-left: auto;
  margin-right: auto;
  //.post-description {
  //  height: 256px;
  //}
  .read-more {
    display: none
  }
  .post-card {
    height: 0;
    width: 100%;
    padding-bottom: 100%;
  }

  @media (max-width: 850px) {
    .post-tags {
      display: none;
    }
    .read-more {
      display: inherit;
    }
    h1 {
      font-size: 22px;
    }
  }
  @media (max-width: 600px) {
   .post-text {
     display: none;
   }
   h1 {
     font-size: 16px;
   }
  }
`;


// export const TagPostsWrapper = styled.div`
//   margin: 0 auto;
//   padding-top: 120px;
//   position: relative;
//   @media (min-width: 990px) {
//     width: 900px;
//   }
//   @media (min-width: 1200px) {
//     width: 1050px;
//   }
//   @media (min-width: 1400px) {
//     width: 1170px;
//   }
//   @media (max-width: 990px) {
//     padding: 80px 45px 30px 45px;
//   }
//   @media (max-width: 575px) {
//     padding: 60px 25px 0 25px;
//   }
//
//   .post-card {
//     margin-bottom: 90px;
//     &:last-child {
//       margin-bottom: 0;
//     }
//     @media (max-width: 990px) {
//       margin-bottom: 70px;
//     }
//     @media (max-width: 575px) {
//       margin-bottom: 50px;
//     }
//   }
// `;

export const TagPageHeading = styled.div`
  padding-left: 210px;
  font-size: 15px;
  font-weight: 400;
  margin-top: 120px;
  margin-bottom: 120px;
  position: relative;
  @media (max-width: 1200px) {
    padding-left: 160px;
  }
  @media (max-width: 990px) {
    padding-left: 10%;
    font-size: 13px;
    margin-top: 80px;
    margin-bottom: 80px;
  }
  @media (max-width: 575px) {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  &:after {
    content: '';
    display: block;
    width: 90px;
    height: 1px;
    background: ${({ theme }) => theme.semiforeground};
    margin-top: 15px;
  }
`;

// export const TagName = styled.h1`
//   font-size: 30px;
//   font-weight: 700;
//   color: ${themeGet('colors.primary', '#2251ff')};
//   margin-bottom: 8px;
//   //@media (max-width: 990px) {
//   //  font-size: 26px;
//   //}
//   //@media (max-width: 575px) {
//   //  font-size: 22px;
//   //}
// `;
export const BlogPostFooter = styled.div`
  margin: auto;
  width: 58%;
  max-width: 100%;
  padding-top: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 990px) {
    padding-top: 40px;
    width: 100%;
  }
  &.center {
    margin: 0 auto;
  }
  span {
    margin-top: 4px;
  }
`;

// export const BlogPostComment = styled.div`
//   margin: auto;
//   width: 58%;
//   max-width: 100%;
//   padding-top: 80px;
//   @media (max-width: 990px) {
//     padding-top: 60px;
//     width: 100%;
//   }
//   &.center {
//     margin: 0 auto;
//   }
// `;


export const BlogPostDetailsWrapper = styled.div`
  margin: 0 auto;
  padding: 90px 40px 120px 40px;
  max-width: 1500px;
  @media (max-width: 560px) {
    padding: 60px 20px 60px 20px;
  }
  @media (max-width: 480px) {
    padding: 40px 10px 40px 10px;
  }
`;

// export const PostTags = styled.div`
//   display: flex;
//   flex-wrap: wrap;
//   align-items: center;
//   @media (max-width: 575px) {
//     display: inline;
//   }
// `;
