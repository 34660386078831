import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

const PostShare = styled.div`
  @media (max-width: 575px) {
    flex-direction: column;
    .share_head {
      display: none;
    }
  }
    display: flex;
    > span {
      flex-shrink: inherit;
    }
  align-items: center;
  > div,
  .react-share__ShareButton {
    cursor: pointer;
    margin-left: 25px;
    //font-size: 22px;
    outline: 0;
    // color: ${({ theme }) => theme.semiforeground};
    //transition: 0.15s ease-in-out;
    @media (max-width: 767px) {
      //font-size: 18px;
      margin-left: 20px;
    } 
    @media (max-width: 575px) {
      margin: 4px 22px 4px 22px;
    }
    &:hover {
      // this is otherwise inherited from button at element level
      color: ${themeGet('colors.primary', '#2251ff')}!important;
    }
    svg {
      display: block;
    }
  }
`;

export default PostShare