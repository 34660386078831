import PostShare from "./social-share.style";
import React from 'react';

import {
    FacebookShareButton,
    TwitterShareButton,
    PinterestShareButton,
    RedditShareButton,
} from 'react-share';

import {
    IoLogoFacebook,
    IoLogoTwitter,
    IoLogoPinterest,
    IoLogoReddit,
} from 'react-icons/io';

interface ShareProps {
    shareUrl: string;
    description: string;
    title: string;
    media: string;
}

const SocialBox: React.FC<ShareProps> = ({ shareUrl, description, title, media }) => {
    return (
        <PostShare>
            <span className="share_head">Share:</span>
            <FacebookShareButton url={shareUrl} quote={description}>
                <IoLogoFacebook className={'transition-escape'}/>
            </FacebookShareButton>
            <TwitterShareButton url={shareUrl} title={title}>
                <IoLogoTwitter className={'transition-escape'}/>
            </TwitterShareButton>
            <PinterestShareButton
                url={shareUrl}
                media={media}
            >
                <IoLogoPinterest className={'transition-escape'}/>
            </PinterestShareButton>
            <RedditShareButton
                url={shareUrl}
                title={`${title}`}
            >
                <IoLogoReddit className={'transition-escape'}/>
            </RedditShareButton>
        </PostShare>
    )
}


export default SocialBox